<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type=='edit'?$t('cip.desk.personalSharing.title.editHeadTitle'):$t('cip.desk.personalSharing.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>
import ServerNameEnum from "@/util/ServerNameEnum";
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {submit, getDetail} from "@/api/desk/personalSharing";

export default {
  name: "personalSharingEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.desk.personalSharing.field.title'),
          prop: 'title',
          span: 9,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.desk.personalSharing.field.title`),
          disabled: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.desk.personalSharing.field.title'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.desk.personalSharing.field.shareLink'),
          prop: "shareLink",
          span: 16,
          type: "textarea",
          maxlength: 200,
          row: true,
          showWordLimit: true,
          disabled: !['edit', 'add'].includes(this.type),
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.desk.personalSharing.field.shareLink`),
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.desk.personalSharing.field.shareLink"),
              trigger: ["blur", 'change']
            }
          ],
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (this.type != 'add') {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          submit({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
                this.initData()
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
